import './NodeEditView.css'
import { useEffect, useState, useReducer } from 'react';
import {apiGetNodeSchema, apiGetNode, apiDeleteNode, apiCreateNode, apiUpdateNode } from '../../services/NodeService';

import MessageView from './MessageView';
import SelectionView from './SelectionView';
import FormView from './FormView';
import RelationshipListView from './RelationshipListView';

function NodeEditView(props)
{
    const [page, setPage] = useState({});
    const [successMsg, successMessage] = useState('');
    const [warningMsg, warningMessage] = useState('');
    const [, forceUpdate] = useReducer(x => x + 1, 0);   
    
    if(props.etag !== page.etag) {
        page.etag = props.etag;
        page.label = props.label;
        page.title = props.label.toUpperCase();
        page.rels_etag = Math.random();
        page.id = props.id;
        page.copied = false;
        page.isSchemaLoading = false;
        page.isSchemaLoaded = false; 
        page.isDataLoading = false;
        page.isDataLoaded = false;
        page.isRelationshipsLoading = false;
        page.isRelationshipsLoaded = false;
        page.data = {};
        page.data.label = props.label;
        page.rels = {};
        page.schema = {};
        page.add_rel = '';
        page.msg = 0;
        setPage(page);
    }    

    useEffect(() => {
        (async () => {            
            if(!page.isSchemaLoading&&!page.isSchemaLoaded) {
                apiGetNodeSchema(props.label)
                    .then(data => {
                        page.schema = data;
                        page.isSchemaLoaded = true;
                        page.isSchemaLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isSchemaLoading = true;
                setPage(page);                
            }
            else if(!page.isDataLoading&&!page.isDataLoaded&&page.id!=='') {
                apiGetNode(page.id)
                    .then(data => {
                        page.label = data.label;
                        page.data = data;
                        page.isDataLoaded = true;
                        page.isDataLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isDataLoading = true;
                setPage(page);    
            }
        })()
    });

    var warning = (<></>);
    var success = (<></>);

    if(warningMsg !== '') {
        warning = (<MessageView type="warning" callback={warningMessage}>{warningMsg}</MessageView>);
    }
    if(successMsg !== '') {
        success = (<MessageView type="success" callback={successMessage}>{successMsg}</MessageView>);
    }     

    var copy = (<></>);

    if(page.id) {
        copy = (<p>{page.copied?<><span className='btn btn-sm btn-disable' onClick={((e) => { navigator.clipboard.writeText(page.id);})}>COPIED</span></>:<><span className='btn btn-sm btn-action' onClick={((e) => {
            navigator.clipboard.writeText(page.id);
            page.copied = true;
            setPage(page);
            forceUpdate();
        })}>COPY ID</span></>} Entity Id: <strong>{page.id}</strong></p>)
    }

    return (<>
        <h1>Edit: {page.title}</h1>
        {success}
        {warning}        
        {copy}
        <p></p>
        <div className='form'>
            <FormView
                schema={page.schema}
                data={page.data}
                callback={(data)=>{
                    page.data = data;
                    setPage(page);
                    forceUpdate();
                }}></FormView>
        </div>
        <p>
            <span className='btn btn-action btn-fixed-width' onClick={(e) => {

                // clean data
                var body = {};
                Object.keys(page.data).forEach((key) => {
                    var bvalue = page.data[key];
                    if(bvalue&&bvalue!=='') {
                        body[key] = bvalue;
                    }
                });

                console.log("Data:", body);

                if(page.id) {
                    apiUpdateNode(page.id, body).then(data => {
                        page.id = data.id;
                        setPage(page);   
                        forceUpdate();             
                        successMessage("Updated node");
                    });
                } else {
                    apiCreateNode(body).then(data => {
                        page.id = data.id;
                        page.data = data;
                        setPage(page);
                        forceUpdate();
                        successMessage("Created node " + page.id);
                        props.changeView({label: page.label, type: 'edit', id: page.id, display: data.display});
                    });
                }
            }}>SAVE</span>            

            {page.id === undefined || page.id === ''?<></>
            :
                <span className='btn btn-failure btn-fixed-width' onClick={(e) => {
                    if(window.confirm('Delete this entity?')) {
                        apiDeleteNode(page.id).then(data => {
                            props.changeView({label: page.title.toLowerCase(), type: 'edit', id: '', deleted_id: page.id});
                        });
                    }
                }}>DELETE</span>
            }
        </p>
        {render_relationships(page, props, setPage, forceUpdate)}
    </>)
}

function render_relationships(page, props, setPage, forceUpdate) {

    if(page.id === '' || page.id === undefined) {
        return (<></>)
    }    
    return (<><hr /><h3>Relationships</h3>
        {render_add_relationships(page, props, setPage, forceUpdate)}
        <RelationshipListView
            etag={page.rels_etag}
            id={page.id}
            changeView={props.changeView}
            callback={(e) => {
                if(e.action === 'reload') {
                    page.add_rel = ''; 
                    page.rels_etag = Math.random();
                    page.isRelationshipsLoaded = false;
                    setPage(page);
                    forceUpdate();
                }
            }}></RelationshipListView>
    </>)
}

function render_add_relationships(page, props, setPage, forceUpdate) {

    if(page.schema.relationships) {
        if(page.add_rel === '') {
            return (<>{page.schema.relationships.map((item)=> {
                return (<span key={'add_rel_'+item}
                    className='btn btn-primary btn-fixed-width'
                    onClick={(e) => {
                        page.add_rel = item;
                        setPage(page);
                        forceUpdate()
                    }}>ADD: {item}</span>)
            })}</>)
        }
        var random = Math.random();
        return (<SelectionView
            etag={random}
            type={page.add_rel}
            id={page.id}
            callback={(e) => {
                if(e.action === 'clear') {
                    page.add_rel = '';
                    page.rels_etag = Math.random();
                    page.isRelationshipsLoaded = false;
                    setPage(page);
                    forceUpdate();
                }
            }}></SelectionView>)
    }
}

export default NodeEditView;