//import logo from './logo.svg';
import './MainLayout.css';
import { useState } from 'react';
import HeaderBar from  '../panels/HeaderBar.js'
import NavigationPanel from '../panels/NavigationPanel';
import NodeListView from '../views/NodeListView';
import NodeEditView from '../views/NodeEditView';
import RelationshipEditView from '../views/RelationshipEditView';

var default_view = {
  type: '',
  id: '',
  label: ''
}

function MainLayout(props) {

  const [view, changeView] = useState(default_view);

  var random = Math.random();

  var changeType = '';

  if(view.type === '') {
    var mainView = (<>Select a node label</>);
  }

  if(view.type === 'list') {
    mainView = (<NodeListView changeView={changeView.bind(this)} label={view.label} display={view.display} etag={random}></NodeListView>)
  }

  if(view.type === 'edit' || view.type === 'edit_node') {
    changeType = 'node';
    mainView = (<NodeEditView changeView={changeView.bind(this)} id={view.id} label={view.label} display={view.display} etag={random}></NodeEditView>)
  }


  if(view.type === 'edit_relationship') {
    changeType = 'relationship';
    mainView = (<RelationshipEditView changeView={changeView.bind(this)} id={view.id} label={view.label} display={view.display} etag={random}></RelationshipEditView>)
  }
  
  return (
    <>
    <HeaderBar></HeaderBar>
    <NavigationPanel changeView={changeView.bind(this)} id={view.id} label={view.label} display={view.display} type={changeType}></NavigationPanel>
    <div className='MainView'>
      <div className='main-container'>
        {mainView}
      </div>
    </div>
    </>
  );
}

export default MainLayout;