
import React, { useState } from 'react';
import './App.css';
import MainLayout from './components/layout/MainLayout';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Authentication state
  const [password, setPassword] = useState(''); // Input state
  const correctPassword = 'password123'; // Dummy password

  // Handle login logic
  const handleLogin = () => {
    if (password === correctPassword) {
      setIsLoggedIn(true);
    } else {
      alert('Incorrect password!');
    }
  };

  if (!isLoggedIn) {
    // Render login page if not authenticated
    return (
      <div className="App">
        <div className="login-container" style={{background: "#000", padding:"1rem"}}>
          <h2>Login</h2>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
    );
  }

  // Render main app if authenticated
  return (
    <div className="App">
      <div className="h100">
        <MainLayout>
          <p>Inner Text</p>
        </MainLayout>
      </div>
    </div>
  );
}

export default App;

/*&
//import logo from './logo.svg';
import './App.css';
import MainLayout from './components/layout/MainLayout';

function App() {
  return (
    <div className="App">
      <div className='h100'>
      <MainLayout>
        <p>Inner Text</p>
      </MainLayout>
      </div>
    </div>
  );
}

export default App;
*/
