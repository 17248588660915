function FormView(props) {

    var inputs = [];
    if(props.schema === undefined || props.schema.properties === undefined) return (<></>);

    if(props.data === undefined) {
        props.data = {};
    }
    if(props.data.properties === undefined) {
        props.data.properties = {};
    }

    const set_default_value = (key) => {
        if(!props.data.properties[key]) {
            props.data.properties[key] = '';
            if(props.schema.properties)
            {
                if(props.schema.properties[key]) {
                    if(props.schema.properties[key].default_value) {
                        props.data.properties[key] = props.schema.properties[key].default_value;
                    }
                }
            }
        } 
    }

    Object.keys(props.schema.properties).forEach(key => {
        var prop = props.schema.properties[key];
        var iptName = 'ipt_'+key;

        if(prop.visibility !== 'PUBLIC') {
            return;
        }
        if(prop.type==='STRING') {
            set_default_value(key);
            var iptString = <p key={iptName}><span className='form-label'>{key}:</span><br />
            <input name={iptName} className='form-ipt'
                key={iptName}
                defaultValue={props.data.properties[key]}
                onChange={(e) => {
                    props.data.properties[key] = e.target.value;
                    props.callback(props.data);
                }} 
            /></p>
            inputs.push(iptString);
        }
        if(prop.type==='ENUM') {
            var options =[];
            set_default_value(key);

            var default_enum_value = props.data.properties[key];
            var default_enum_label = props.data.properties[key];

            

            for(var i =0; i < prop.selection.length ; i++ ) {
                var display = prop.selection[i];
                var value = prop.selection[i];
                var opt_key = 'opt_key_' + value;
                if(prop.selection_display[i] !== undefined) {
                    display = prop.selection_display[i];
                    if(value === default_enum_value) {
                        default_enum_label = display;
                    }
                }
                if(value === default_enum_value) {
                    options.push((<option key={opt_key} value={value} selected>{display}</option>));
                } else {
                    options.push((<option key={opt_key} value={value}>{display}</option>));
                }
            }

            //console.warn(default_enum_value,default_enum_label);

            var iptSelect = (<p key={iptName}>
                <span className='form-label'>{key}:</span><br />
                <select
                    name={iptName}
                    className='form-ipt'
                    defaultValue={{label: default_enum_label, value: default_enum_value}}
                    onChange={(e) => {
                        console.log(e);
                        props.data.properties[key] = e.target.value;
                        props.callback(props.data);
                    }}
                >{options}</select>
            </p>)
            inputs.push(iptSelect);
        }
    });
    return (<>{inputs}</>)
}

export default FormView;