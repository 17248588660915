import './NodeListView.css'
import { useEffect, useState, useReducer } from 'react';
import {apiGetNodeSchema, apiSearchNodes} from '../../services/NodeService'
import PagingView from './PagingView';
import WaitingView from './WaitingView';
import FilterView from './FilterView';

var default_state = {
    isSchemaLoading: false,
    isSchemaLoaded: false,

    isDataLoading: false,
    isDataLoaded: false,

    schema: {},
    items: [],
    filters: [],
    filter: {},
    label: '',
    total:0,
    count:0,
    offset: 0,
    limit: 20
}

export default function NodeListView (props) {

    var label = props.label;
    const [page, setPage] = useState(default_state);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    if(label !== page.label || props.etag!== page.etag) {
        page.etag = props.etag;
        page.label = label;
        page.title = page.label.toUpperCase();
        page.isSchemaLoaded = false;
        page.isSchemaLoading = false;
        page.isDataLoaded = false;
        page.isDataLoading = false;

        page.total=0;
        page.count=0;
        page.offset=0;
        page.limit=10;
        page.filter=[];
        page.filters = [];

        setPage(page);
    } 

    useEffect(() => {
        (async () => {            
            if(!page.isSchemaLoading&&!page.isSchemaLoaded) {
                apiGetNodeSchema(label)
                    .then(data => {
                        page.schema = data;
                        page.isSchemaLoaded = true;
                        page.isSchemaLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isSchemaLoading = true;
                setPage(page);
                forceUpdate();             
            }
            if(page.isSchemaLoaded&&!page.isDataLoading&&!page.isDataLoaded) {

                var body = {
                    offset: page.offset,
                    limit: page.limit,
                    label: page.label
                };                                
                if(page.filter.length > 0) {
                    body.and = page.filter;
                }

                apiSearchNodes(body)
                    .then(data => {
                        page.items = data.results;
                        page.offset = data.paging.offset;
                        page.count = data.paging.count;
                        page.total = data.paging.total;
                        page.isDataLoaded = true;
                        page.isDataLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isDataLoading = true;
                setPage(page);  
                forceUpdate();  
            }
        })()
    });

    var table = <></>

    if(page.isSchemaLoaded&&page.isDataLoaded) {
        var items = []
        page.items.forEach((item) => {
            var values = []
            Object.keys(page.schema.properties).forEach((key) => {
                var val = null;
                if(item.properties[key] !== undefined) {
                    val = item.properties[key];
                }
                if(typeof val === 'object' ) {
                    val = null;
                }
                if(val && page.schema.properties[key].list) {
                    values.push(
                        <div key={item.id +'unq_'+key} className='grid-container'>
                            <div className='item-label'><strong>{key}:</strong></div>
                            <div className='item-value'>{val}</div>
                        </div>)
                }
            });
            items.push(<div key={'entity_'+item.id}
                    onClick={(k) => {
                        props.changeView({type: 'edit', id: item.id, label: page.label, display: item.display})
                    }}
                    className='entity-item'>
                    <div className='entity-title'>{item.display}</div>
                    {values}
                </div>)
        })
        table = (<div>{items}</div>)
    }

    var filtersAddBtns = [];

    const update_value = (data) => {
        for(var i=page.filters.length-1; i>=0;i--) {
            if(page.filters[i].elem.key===data.id) {
                if(!page.filters[i].data) {
                    page.filters[i].data = {};
                }
                page.filters[i].data[data.field] = data.value;
                page.filters[i].data.param = data.param;
                setPage(page);
            }
        }
    };
    const update_filter = () => {        
        page.filter = filters_to_search_filter(page.filters, page.schema);
        page.isDataLoaded = false;
        page.isDataLoading = false;
        page.offset = 0;
        setPage(page);
        forceUpdate();
    }
    if(page.isSchemaLoaded) {
        Object.keys(page.schema.properties).forEach((key) => {
            var prop = page.schema.properties[key];
            if(prop.queryable) {
                var arnd = "add_key_" + (Math.random() + 1).toString(36).substring(4);
                var addBtn = <span key={arnd} className='btn btn-sm btn-action' onClick={(e) => {
                    var rnd = "filter_key_" + (Math.random() + 1).toString(36).substring(4);
                    page.filters.push({data:{}, elem:<FilterView update_value={update_value}
                        data={{}}
                        key={rnd}
                        filter={rnd}
                        filter_name={key}
                        schema={prop} remove={
                            (e) => {
                                for(var i=page.filters.length-1; i>=0;i--) {
                                    if(page.filters[i].elem.key===e) {                                   
                                        page.filters.splice(i,1);
                                        setPage(page);
                                        update_filter();
                                    }
                                }
                            }                            
                        }
                        trigger={()=>{update_filter();}}
                        ></FilterView>})
                    setPage(page);
                    forceUpdate();
                }}>ADD FILTER: {key.toLocaleUpperCase()}</span>
                filtersAddBtns.push(addBtn);
            }
        });
    }
    var pagingView = <PagingView offset={page.offset} limit={page.limit} count={page.count} total={page.total} goto={(e) => {
        page.offset = e;
        page.isDataLoading = true;
        page.isDataLoaded = false;
        setPage(page);
        var body = {
            offset: page.offset,
            limit: page.limit,
            label: page.label
        };
        apiSearchNodes(body)
            .then(data => {
                page.items = data.results;
                page.offset = data.paging.offset;
                page.count = data.paging.count;
                page.total = data.paging.total;
                page.isDataLoaded = true;
                page.isDataLoading = false;
                setPage(page);
                forceUpdate();
            });        
        forceUpdate();
    }}></PagingView>

    return(
    <>
        <h1>Search: {page.title} {page.items.total}</h1>
        <p><span className='btn btn-primary' onClick={(k)=>props.changeView({type: 'edit', label:page.label, id: ''})}>CREATE NEW</span></p>
        {
            filtersAddBtns.length === 0 ? <></>
            : <div className='filter-btns'>{
                filtersAddBtns.map( (item) => {
                    return item;
                })
            }</div>
        }
        {
            page.filters.length === 0 ? <></>
            : <div className='filter-btns'> {
                page.filters.map( (item) => {
                    return item.elem;
                })
            }</div>
        }
        {
            page.filters.length === 0 && page.isDataLoaded ? <></>
            : <div className='filter-btns'> {
                <span className='btn btn-highlight' onClick={(e)=> update_filter() }>SEARCH</span>
            }</div>
        }
        {
            page.isDataLoading||page.isSchemaLoading?<WaitingView></WaitingView>
            :
            <>{pagingView}
            {table}
            {(page.offset > 0 || page.count > 0)?pagingView:<></>}
            </>
        }
    </>)
}

function filters_to_search_filter(filters, schema)
{
    var query = [];
    filters.map((filter)=> {     
        if(filter.data.value ) {
            var op = filter.data.operator.toLowerCase();
            var q = {};
            q.property = filter.data.param;
            q[op] = filter.data.value;
            query.push(q);
        }
        return '';
    });
    return query;
}