import './MessageView.css'

function MessageView (props) {

    var classes = "msg " + props.type;
    return (<div className={classes}>
        <div>{props.children}</div>
        <div className="closeBtn"
            onClick={(e) => {
                if(props.callback) {
                    props.callback('');
                }
            }}>X</div>
    </div>);
}

export default MessageView;