import './FilterView.css'
import { useState/*, useReducer*/ } from 'react';

export default function FilterView(props) {

    const [page, setPage] = useState({
        value: undefined,
        operator: undefined,
        param: undefined
    });
    //const [, forceUpdate] = useReducer(x => x + 1, 0);

    if(!props.schema || !props.filter) return <></>
    
    var operator = [];
    var selection = [];

    const get_value = (field, default_value) => {
        if(page && page[field]) {
            return page[field];
        }
        return default_value;
    }

    const update_value = (data) => {
        if(page[data.field] && page[data.field] !== data.value) {
            page[data.field] = data.value;
            setPage(page);
        }
        props.update_value(data);
    }

    if(props.schema.type==='ID') {
        operator.push({key:"SAME", value:"Equals"});
        update_value({param: props.filter_name, id: props.filter, field: "operator", value: get_value('operator', 'SAME')});
        update_value({param: props.filter_name, id: props.filter, field: "value", value: get_value('value', '')});
    }
    else if(props.schema.type==='STRING') {
        operator.push({key:"EQ", value:"Equals"});
        operator.push({key:"BEGINS", value:"Begins With"});
        update_value({param: props.filter_name, id: props.filter, field: "operator", value: get_value('operator', 'SAME')});
        update_value({param: props.filter_name, id: props.filter, field: "value", value: get_value('value', '')});
    }
    else if(props.schema.type==='ENUM' && props.schema.selection) {
        var selection_count = props.schema.selection.length;
        props.update_value({param: props.filter_name, id: props.filter, field: "operator", value: get_value('operator', 'SAME')});
        props.update_value({param: props.filter_name, id: props.filter, field: "value", value: get_value('value', props.schema.selection[0])});
        for(var i=0; i<selection_count; i++) {
            var key = props.schema.selection[i];
            var value = props.schema.selection_display && props.schema.selection_display.length === selection_count
                ? props.schema.selection_display[i]
                : key;
            selection.push({key, value});
        }
    }
    var condition_ctrl = <></>
    var input_ctrl = <input className='filter-ipt filter-text'  type='string'
        onChange={(e)=> update_value({param: props.filter_name, id: props.filter, field: "value", value: e.target.value})}
        onKeyUp={(e)=>{ if(e.keyCode === 13) { props.trigger();}}}
        />

    if(operator.length) {
        condition_ctrl = <select className='filter-ipt filter-selection' onChange={(e)=> update_value({param: props.filter_name, id: props.filter, field: "operator", value: e.target.value})}>
            {operator.map((item) => {
                return <option value={item.key}>{item.value}</option>
            })}
        </select>
    }
    if(selection.length) {
        condition_ctrl = <select className='filter-ipt filter-selection' onChange={(e)=> update_value({param: props.filter_name, id: props.filter, field: "operator", value: e.target.value})}>
            {selection.map((item) => {
                return <option value={item.key}>{item.value}</option>
            })}
        </select>
    }

    var className = 'filter-container';

    var valueCtrl = <div>
        <strong>VALUE:</strong><br />
        {input_ctrl}
    </div>

    if(props.schema.type==='ENUM') {
        condition_ctrl = <select className='filter-ipt filter-selection-enum' onChange={(e)=> {console.warn(e);update_value({param: props.filter_name, id: props.filter, field: "value", value: e.target.value});}}>
        {selection.map((item) => {
            return <option value={item.key}>{item.value}</option>
        })}
        </select>
        className += ' filter-container-enum';
        valueCtrl = <></>
    }

    return <div className={className}>    
        <div>
            <strong>FILTER: {props.filter_name}</strong><br />
            {condition_ctrl}
        </div>
        {valueCtrl}
        <div style={{textAlign:'right'}}>
            <span className='btn btn-sm btn-failure' onClick={(e) => {
                props.remove(props.filter);
            }}>REMOVE</span>
        </div>
    </div>

}

/*
[JsonPropertyName("match")] public string? Match { get; set; }

[JsonPropertyName("eq")] public object? EqualTo { get; set; }

[JsonPropertyName("gt")] public object? GreaterThan { get; set; }

[JsonPropertyName("gte")]public object? GreaterThanOrEqualTo { get; set; }

[JsonPropertyName("lt")] public object? LessThan { get; set; }

[JsonPropertyName("lte")] public object? LessThanOrEqualTo { get; set; }

[JsonPropertyName("regex")] public object? Regex { get; set; }

[JsonPropertyName("begins")] public object? BeginWith { get; set; }

[JsonPropertyName("query")] public object? Query { get; set; }
*/