import './SelectionView.css'
import { useEffect, useState } from 'react';
import {apiGetNodeSchema, apiCreateRelationship} from '../../services/NodeService'
import FormView from './FormView';
import MessageView from './MessageView';

import PasteIcon from '../../assets/icons/paste.svg';

function SelectionView (props) {

    const [page, setPage] = useState({});
    const [, setTrigger] = useState(0);
    const [successMsg, successMessage] = useState('');
    const [warningMsg, warningMessage] = useState('');
    
    if(props.etag !== page.etag) {
        page.etag = props.etag;
        page.id =props.id;
        page.isSchemaLoading = false;
        page.isSchemaLoaded = false;
        page.schema = {};
        page.type= props.type;
        page.data= {
            id_a: props.id,
            id_b: '',
            properties: {}
        };
        page.total=0;
        page.count=0;
        page.offset= 0;
        page.limit= 20;
        setPage(page);
    }

    useEffect(() => {
        (async () => {            
            if(!page.isSchemaLoading&&!page.isSchemaLoaded) {
                apiGetNodeSchema(props.type)
                    .then(data => {
                        page.schema = data;
                        page.isSchemaLoaded = true;
                        page.isSchemaLoading = false;
                        setPage(page);
                        setTrigger(Math.random()); 
                    });
                page.isSchemaLoading = true;
                setPage(page);                
            }
        })()
    });

    var warning = (<></>);
    var success = (<></>);

    if(warningMsg !== '') {
        warning = (<MessageView type="warning" callback={warningMessage}>{warningMsg}</MessageView>);
    }
    if(successMsg !== '') {
        success = (<MessageView type="success" callback={successMessage}>{successMsg}</MessageView>);
    } 

    return (<div className='selection-view'>        
        <div className='form'>
            <h3>{props.type}</h3>
            {success}
            {warning}
            <p>
                <span className='form-label'>Entity Id:</span><br />
                <input type="string"
                    className='form-ipt'
                    name="selection"
                    placeholder='Identity e.g. abcd1234'
                    defaultValue={page.data.id_b}
                    onChange={(e) => {
                        page.data.id_b = e.target.value;
                        setPage(page);
                    }} 
                />
                <img src={PasteIcon} alt={"paste"}
                    style={{maxWidth: '2rem', maxHeight:'2rem', marginLeft: '0.5rem', marginBottom: '0.4rem', verticalAlign:'bottom', cursor:'pointer'}}
                    onClick={(e)=> {
                        navigator.clipboard.readText().then((clipText) => {
                            page.data.id_b = clipText;
                            setPage(page);
                            setTrigger(Math.random()); 
                        });
                    }}/>
            </p>
            <FormView
                callback={(data)=>{
                    page.data.properties = data.properties;
                    setPage(page);
                }} data={{}}
                schema={page.schema}></FormView>
            <p>
                <span className='btn btn-action btn-fixed-width' onClick={(e) => {
                    apiCreateRelationship(page.data.id_a, page.data.id_b, {type: page.type, properties: page.data.properties}).then(data => {
                        successMessage("Created relationship");
                        props.callback( {action: 'clear'});
                    }).catch(error=>{
                        console.warn(error);
                        warningMessage("Error creating relationship");
                    })                    
                }}>CREATE RELATIONSHIP</span>
                <span className='btn btn-failure btn-fixed-width' onClick={(e)=>{ props.callback( {action: 'clear'} ); }}>CANCEL</span>
            </p>
        </div>
    </div>)
}

export default SelectionView;