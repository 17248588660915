import './PagingView.css'

function PagingView(props) {

    var current = props.offset + 1;
    if(props.count === 0) {
        current = 0;
    }

    var prev = <span className='pg-btn pg-btn-disabled pg-prev'>PREV</span>
    if(props.offset > 0) {
        var prevValue = props.offset - props.limit;
        if(prevValue < 0) {
            prevValue = 0;
        }
        prev = <span className='pg-btn pg-prev' onClick={(e)=> {props.goto(prevValue)}}>PREV</span>
    }

    var next = <span className='pg-btn pg-btn-disabled pg-next'>NEXT</span>
    if(props.offset + props.count < props.total) {
        var nextValue = props.offset + props.count;
        next = <span className='pg-btn pg-next' onClick={(e)=> {props.goto(nextValue)}}>NEXT</span>
    }

    return <div className='pg-view'>
        {prev} | {current} to {props.offset + props.count} of {props.total} | {next}
    </div>
}

export default PagingView;