import { BASE_URL } from "../AppConfig"

import axios from 'axios';

async function apiDeleteRelationship(id) {
    const { data } = await axios.delete(BASE_URL + '/relationship/'+id);
    return data;
}

async function apiListNodeSchemas() {
    const { data } = await axios.get(BASE_URL + '/schema/list/node');
    return data;
}

async function apiGetNodeSchema(label) {
    const { data } = await axios.get(BASE_URL + '/schema/node/' + label);
    return data;
}

async function apiSearchNodes(params) {
    const { data } = await axios.post(BASE_URL + '/search/node', params);
    return data;
}

async function apiGetNode(id) {
    const { data } = await axios.get(BASE_URL + '/node/' +id);
    return data;
}

async function apiGetRelationship(id) {
    const { data } = await axios.get(BASE_URL + '/relationship/' +id);
    return data;
}

async function apiDeleteNode(id) {
    const { data } = await axios.delete(BASE_URL + '/node/' +id);
    return data;
}

async function apiCreateNode(params) {
    const { data } = await axios.post(BASE_URL + '/node', params);
    return data;
}

async function apiGetNodeRelationships(id, params) {
    const { data } = await axios.post(BASE_URL + '/node/' + id + '/relationship', params);
    return data;
}

async function apiCreateRelationship(a, b, params) {
    const { data } = await axios.put(BASE_URL + '/node/' + a + '/relationship/' + b, params);
    return data;
}

async function apiUpdateNode(id,params) {
    const { data } = await axios.put(BASE_URL + '/node/' + id, params);
    return data;
}

async function apiUpdateRelationship(id,params) {
    const { data } = await axios.put(BASE_URL + '/relationship/' + id, params);
    return data;
}

export {
    apiListNodeSchemas,
    apiGetNodeSchema,
    apiSearchNodes,
    apiGetNodeRelationships,
    apiGetNode,
    apiDeleteNode,
    apiCreateNode,
    apiUpdateNode,
    apiCreateRelationship,
    apiDeleteRelationship,
    apiGetRelationship,
    apiUpdateRelationship,
}