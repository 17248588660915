import './RelationshipListView.css'
import { useEffect, useState } from 'react';
import {apiGetNodeRelationships, apiDeleteRelationship} from '../../services/NodeService'
import PagingView from './PagingView';
import WaitingView from './WaitingView';

export default function RelationshipListView (props) {
    
    const [page, setPage] = useState({});
    const [, setTrigger] = useState(0);
    
    if(props.id !== page.id || page.etag !== props.etag) {
        page.id = props.id;
        page.etag = props.etag;
        page.isDataLoading = false;
        page.isDataLoaded = false;
        page.results =  [];
        page.paging= {
            offset: 0,
            limit: 10,
        };
        setPage(page);
        setTrigger(Math.random());
    }

    useEffect(() => {
        (async () => {            
            if(!page.isDataLoading&&!page.isDataLoaded&&page.id!=='') {
                apiGetNodeRelationships(page.id, page.paging)
                    .then(data => {
                        page.data = data;
                        page.isDataLoaded = true;
                        page.isDataLoading = false;
                        page.results = data.results;
                        page.paging.offset = data.paging.offset;
                        page.paging.count = data.paging.count;
                        page.paging.total = data.paging.total;
                        setPage(page);
                        setTrigger(Math.random());
                    });
                page.isDataLoading = true;
                setPage(page);
            }
        })()
    });

    if(!props.id) return <></>;
    if(page.isDataLoading) return <p>Loading relationship list...</p>
    if(page.paging.count === 0) {
        return <p>No relationships found</p>
    }
    var pagingView = <PagingView offset={page.paging.offset} limit={page.paging.limit} count={page.paging.count} total={page.paging.total} goto={(e) => {
        page.isDataLoading = true;
        page.isDataLoaded = false;

        page.paging.offset = e;
        if(!page.paging.offset) {
            page.paging.offset = 0;
        }

        apiGetNodeRelationships(page.id, page.paging)
            .then(data => {
                page.data = data;
                page.isDataLoaded = true;
                page.isDataLoading = false;
                page.results = data.results;
                page.paging.offset = data.paging.offset;
                page.paging.count = data.paging.count;
                page.paging.total = data.paging.total;
                setPage(page);
                setTrigger(Math.random());
            });
        setPage(page);

    } }></PagingView>

    if(page.isDataLoading) {
        return <WaitingView></WaitingView>
    }
    return <>
    {pagingView}
    {page.results.map((item)=>{
        var label = item.node.labels.filter(e => e !== 'entity')[0];
        var node_display = item.node.display;
        var relationship_display = item.relationship.display ? item.relationship.display : node_display;
        var relId = item.relationship.id;
        var key = "rel_" + relId;

        return <div className='rel-item' key={key}>
            <span>{item.relationship.direction} | {item.relationship.type} | {relationship_display}</span>
            <span className='rel-item-end'>
                <span className='btn btn-sm btn-good' onClick={(k)=>props.changeView({type: 'edit_node', id: item.node.id, label: label, display: node_display})}>GOTO NODE</span>
                <span className='btn btn-sm btn-highlight' onClick={(k)=>props.changeView({type: 'edit_relationship', id: item.relationship.id, label: item.relationship.type})}>EDIT REL</span>
                <span className='btn btn-sm btn-failure' onClick={(e)=>{
                    var ans = window.confirm("Are you sure you wish to delete this relationship");
                    if(ans) {
                        apiDeleteRelationship(relId).then(data => {  
                            props.callback({action:'reload'});
                            page.etag = Math.random();
                            setPage(page);
                        });
                    }
                }}>DELETE</span>
            </span>
        </div>

    })}
    </>
}