import './RelationshipEditView.css'
import { useEffect, useState, useReducer } from 'react';
import {apiGetNodeSchema, apiGetRelationship, apiDeleteRelationship, apiCreateNode, apiUpdateRelationship } from '../../services/NodeService';

import MessageView from './MessageView';
import FormView from './FormView';


export default function RelationshipEditView(props)
{
    const [page, setPage] = useState({});
    const [successMsg, successMessage] = useState('');
    const [warningMsg, warningMessage] = useState('');
    const [, forceUpdate] = useReducer(x => x + 1, 0);   
    
    if(props.etag !== page.etag) {
        page.etag = props.etag;
        page.label = props.label;
        page.title = props.label.toUpperCase();
        page.rels_etag = Math.random();
        page.id = props.id;
        page.copied = false;
        page.isSchemaLoading = false;
        page.isSchemaLoaded = false; 
        page.isDataLoading = false;
        page.isDataLoaded = false;
        page.isRelationshipsLoading = false;
        page.isRelationshipsLoaded = false;
        page.data = {};
        page.data.label = props.label;
        page.rels = {};
        page.schema = {};
        page.add_rel = '';
        page.node_a = {};
        page.node_b = {};
        page.msg = 0;
        setPage(page);
    }    

    useEffect(() => {
        (async () => {            
            if(!page.isSchemaLoading&&!page.isSchemaLoaded) {
                apiGetNodeSchema(props.label)
                    .then(data => {
                        page.schema = data;
                        page.isSchemaLoaded = true;
                        page.isSchemaLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isSchemaLoading = true;
                setPage(page);                
            }
            else if(!page.isDataLoading&&!page.isDataLoaded&&page.id!=='') {
                apiGetRelationship(page.id)
                    .then(data => {
                        page.label = data.relationship.type;
                        page.data = data.relationship;
                        page.node_a = data.node_a;
                        page.node_b = data.node_b;
                        page.isDataLoaded = true;
                        page.isDataLoading = false;
                        setPage(page);
                        forceUpdate();
                    });
                page.isDataLoading = true;
                setPage(page);    
            }
        })()
    });

    var warning = (<></>);
    var success = (<></>);

    if(warningMsg !== '') {
        warning = (<MessageView type="warning" callback={warningMessage}>{warningMsg}</MessageView>);
    }
    if(successMsg !== '') {
        success = (<MessageView type="success" callback={successMessage}>{successMsg}</MessageView>);
    }     

    var copy = (<></>);

    if(page.id) {
        copy = (<p>{page.copied?<><span className='btn btn-sm btn-disable' onClick={((e) => { navigator.clipboard.writeText(page.id);})}>COPIED</span></>:<><span className='btn btn-sm btn-action' onClick={((e) => {
            navigator.clipboard.writeText(page.id);
            page.copied = true;
            setPage(page);
            forceUpdate();
        })}>COPY ID</span></>} Relationship Id: <strong>{page.id}</strong></p>)
    }

    var has_props = page.schema.properties && Object.keys(page.schema.properties).length;

    return (<>
        <h1>Edit: {page.title}</h1>
        {success}
        {warning}        
        {copy}
        <p></p>
        {has_props?<div className='form'>
            <FormView
                schema={page.schema}
                data={page.data}
                callback={(data)=>{
                    page.data = data;
                    setPage(page);
                    forceUpdate();
                }}></FormView>
        </div>:<></>}
        
        <p>
            {has_props?
                <span className='btn btn-action btn-fixed-width' onClick={(e) => {

                    // clean data
                    var body = {};
                    Object.keys(page.data).forEach((key) => {
                        var bvalue = page.data[key];
                        if(bvalue&&bvalue!=='') {
                            body[key] = bvalue;
                        }
                    });

                    console.log("Data:", body);

                    if(page.id) {
                        apiUpdateRelationship(page.id, body).then(data => {
                            page.id = data.id;
                            setPage(page);   
                            forceUpdate();             
                            successMessage("Updated node");
                        });
                    } else {
                        apiCreateNode(body).then(data => {
                            page.id = data.id;
                            page.data = data;
                            setPage(page);
                            forceUpdate();
                            successMessage("Created node " + page.id);
                            props.changeView({label: page.label, type: 'edit', id: page.id, display: data.display});
                        });
                    }
                }}>SAVE</span>   
            :<></>}         

            {page.id === undefined || page.id === ''?<></>
            :
                <span className='btn btn-failure btn-fixed-width' onClick={(e) => {
                    if(window.confirm('Delete this relationship?')) {
                        var node_label = page.node_a.labels.filter(e => e !== 'entity')[0];
                        apiDeleteRelationship(page.id).then(data => {
                            props.changeView({label: node_label, type: 'edit', id: page.node_a.id, deleted_id: page.id});
                        });
                    }
                }}>DELETE</span>
            }
        </p>
        {
        page.node_a === undefined && page.node_b === undefined?<></>
            :
            <div className='rel-node-summary'>
                {render_node_summary(props, page.node_a, 'A')}
                {render_node_summary(props, page.node_b, 'B')}
            </div>
        }
    </>)
}

function render_node_summary (props, node, title) {

    if(!node.labels) {
        return <></>;
    }

    console.log(node);
    var node_label = node.labels.filter(e => e !== 'entity')[0];

    var values = []
    if(node && node.properties){
        Object.keys(node.properties).forEach((key) => {
            var val = null;
            if(node.properties[key] !== undefined) {
                val = node.properties[key];
            }
            if(typeof val === 'object' ) {
                val = null;
            }
            if(val) {
                values.push(
                <div key={node.id +'unq_'+key} className='grid-container'>
                    <div className='item-label'><strong>{key}:</strong></div>
                    <div className='item-value'>{val}</div>
                </div>)
            }
        });
    }

    return <div className='node-summary'>
        <h4 className='node-summary-title'>NODE {title}: <span className='node-summary-id'>{node.id}</span></h4>
        <p><span className='btn btn-sm btn-good' onClick={(k)=>props.changeView({type: 'edit_node', id: node.id, label: node_label, display: node.display})}>GOTO NODE</span></p>
        {values}
    </div>
}

