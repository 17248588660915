import './NavigationPanel.css'
import { useEffect, useState } from 'react';
import {apiListNodeSchemas} from '../../services/NodeService';

var default_state = {
    isLoading: false,
    isLoaded: false,
    items: [],
    previous: [],
    current_id: '',
    id: ''
}

function NavigationPanel (props) {

    const [page, setPage] = useState(default_state);
    const [trigger, setTrigger] = useState(0);

    if(props.id !== page.id && props.type == 'node') {
        page.id = props.id;
        page.label = props.label;
        page.display = props.display;

        if(props.deleted_id !== undefined && props.deleted_id !== '') {
            for(var k = page.previous.length - 1; k > 0; k--) {
                if(page.previous[k].id === props.deleted_id) {
                    page.previous.splice(k,1);
                }
            }
        }
        if(props.id !== '' && props.id !== undefined) {
            //const found = page.previous.find(element => element.id > props.id);
            page.previous.unshift({id: page.id, label: page.label, display: page.display});
            for(var i = page.previous.length - 1; i > 0; i--) {
                if(page.previous[i].id === page.id) {
                    page.previous.splice(i,1);
                }
            }
            page.previous = page.previous.slice(0,10);
        }
        setPage(page);
    }

    useEffect(() => {
        (async () => {            
            if(!page.isLoading&&!page.isLoaded) {
                apiListNodeSchemas()
                    .then(items => {
                        page.items = items;
                        page.isLoaded = true;
                        page.isLoading = false;
                        setPage(page);
                        setTrigger(trigger + 1);

                    });
                page.isLoading = true;
                setPage(page);    
            }
        })()
    });

    if(!page) {
        return (<></>)
    }
    var items = [];
    page.items.forEach(e => { items.push(<li className="label-tag" key={'view_li_'+e.label} onClick={(k)=>props.changeView({type: 'list', label: e.label})}>{e.label}</li>) })

    if(!page.isLoaded) {
        return (<div>Loading</div>);
    }
    return (
        <div className="NavPanel container">
            <h4>ENTITIES</h4>
            <ul className="label-tag-list">
                {items}
            </ul>
            
            <h4 className='top-space'>Previous Lookups</h4>
            {show_previous(page, setPage, props, setTrigger)}
            
        </div>
    );
}

function show_previous(page, setPage, props, setTrigger) {

    if(page.previous) {
        return (<>{page.previous.map( (item) => {
            var display = item.id;
            if(item.display) {
                display = item.display;
            }
            return (<div className="previous-item"
                key={'view_prev_'+item.id}
                onClick={(k)=>props.changeView({type: 'edit', id: item.id, label: item.label, display: item.display})}
            ><strong>{item.label}:</strong> {display}</div>)
        })}
        <p><span className='btn btn-sm btn-failure' onClick={(e)=>{page.previous = []; setPage(page); setTrigger(Math.random())}}>CLEAR</span></p>
        </>)
    }    
    return (<></>)
}

export default NavigationPanel;